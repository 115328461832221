import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBreakdownList() {
  // Use toast
  const toast = useToast()
  const refBreakdownListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'code', sortable: true },
    { key: 'name', sortable: false },
    { key: 'breakdownTypeID', label: 'Breakdown Type', sortable: false },
    { key: 'workCenterID', label: 'WorkCenter', sortable: false },
    { key: 'isActive' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalBreakdowns = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const row = ref([])
  const dataCollectionBreakdowns = ref([])
  const search = ref('')
  const workcenters = ref([])
  const breakdowntypes = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refBreakdownListTable.value ? refBreakdownListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalBreakdowns.value,
    }
  })

  const refetchData = () => {
    refBreakdownListTable.value.refresh()
    GetData()
  }

  watch([currentPage, perPage, search], () => {
    refetchData()
  })

  function GetBreakdowns() {
    store.dispatch('pamis-breakdown/fetchBreakdowns')
      .then(response => {
        const breakdownResult = response.data.filter(x => x.isDelete == false)
        row.value = breakdownResult
        totalBreakdowns.value = breakdownResult.length

        row.value.forEach(element => {
          const breakdownlist = dataCollectionBreakdowns.value.filter(x => x.id == element.breakdownGUID)

          if (breakdownlist != null && breakdownlist.length > 0) {
            element.breakdown = breakdownlist[0].breakdownName
          }
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Breakdown list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  function GetDataColectionBreakdowns() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-breakdown/fetchBreakdowns')
        .then(response => {
          let breakdownResult = []

          if (response.data != null && response.data.length > 0) {
            breakdownResult = response.data.filter(x => x.isDelete == false && x.isActive == true)
            resolve(breakdownResult)
            dataCollectionBreakdowns.value = breakdownResult
          } else {
            reject(breakdownResult)
          }
        })
        .catch(() => {
          reject(breakdownResult)
        })
    })
  }
  function GetWorkCenters() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-breakdown/fetchWorkCenters')
        .then(response => {
          let breakdownTypeResult = []

          if (response.data != null && response.data.length > 0) {
            breakdownTypeResult = response.data.filter(x => x.isDelete == false && x.isActive == true)
            resolve(breakdownTypeResult)
            workcenters.value = breakdownTypeResult
          } else {
            reject(breakdownTypeResult)
          }
        })
        .catch(() => {
          reject(breakdownTypeResult)
        })
    })
  }
  function GetBreakdownTypes() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-breakdown/fetchBreakdownTypes')
        .then(response => {
          let breakdownTypeResult = []

          if (response.data != null && response.data.length > 0) {
            breakdownTypeResult = response.data.filter(x => x.isDelete == false && x.isActive == true)
            resolve(breakdownTypeResult)
            breakdowntypes.value = breakdownTypeResult
          } else {
            reject(breakdownTypeResult)
          }
        })
        .catch(() => {
          reject(breakdownTypeResult)
        })
    })
  }

  async function GetData() {
    await GetDataColectionBreakdowns()
    await GetBreakdownTypes()
    await GetBreakdowns()
    await GetWorkCenters()
  }

  GetData()

  return {
    row,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalBreakdowns,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refBreakdownListTable,
    workcenters,
    breakdowntypes,
    refetchData,
  }
}
