<template>
  <b-sidebar
    id="add-new-breakdown-sidebar"
    :visible="isAddNewBreakdownSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-breakdown-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Breakdown" : actionType == 1 ? " Update Breakdown" : "Delete Breakdown" }}

        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Breakdown Name"
            rules="required"
          >
            <b-form-group
              label="Breakdown Name"
              label-for="breakdown-name"
            >
              <b-form-input
                id="breakdown-name"
                v-model="currentBreakdown.name"
                :disabled="actionType==2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Breakdown Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Code"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="currentBreakdown.code"
              :disabled="actionType==2"
              autofocus
              trim
              placeholder="Code"
            />
          </b-form-group>
          <b-form-group label="BreakdownType">
            <treeselect
              id="breakdownTypeID"
              v-model="currentBreakdownType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="breakdownTypes"
              :disabled="actionType==2"
              class="select-size-lg"
            >
              <template #option="{ id, name }">
                <span> {{ name }}</span><br>
                <small class="text-muted"> {{ id }} </small>
              </template>
            </treeselect>
          </b-form-group>

          <b-form-group
            label="Company"
          >
            <v-select
              id="companyId"
              v-model="currentCompany"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="companies"
              class="select-size-lg"
              v-on:input="changeCompany"
              :disabled="actionType==2"
            >
              <template #option="{ id, name }">
                <span> {{ name }}</span><br>
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group
            label="Site"
          >
            <v-select
              id="siteId"
              v-model="currentSite"
              label="name"
              :options="sites"
              class="select-size-lg"
              v-on:input="changeSite"
              :disabled="actionType==2"
            >
              <template #option="{ id, name }">
                <span> {{ name }}</span><br>
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group
            label="Workcenter"
          >
            <treeselect
              id="workCenterId"
              v-model="currentWorkCenter"
              :multiple="false"
              :options="workCenters"
            >
              <template #option="{ id, label }">
                <span> {{ label }}</span><br>
                <small class="text-muted"> {{ id }} </small>
              </template>
            </treeselect>

          </b-form-group>

          <b-form-checkbox
            id="input-isActive"
            v-model="currentIsActive"
            class="mt-2"
            :disabled="actionType==2"
          >
            IsActive
          </b-form-checkbox>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="actionType != 2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ currentBreakdown==null|| currentBreakdown.id==null? 'Add':'Update' }}
            </b-button>
            <b-button
              v-if="currentBreakdown!=null&& currentBreakdown.id!=null && actionType == 2"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              variant="outline-danger"
              @click="deleteBreakdown"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  inject, toRefs, ref, watch,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
// import CategorySelector from '../components/SingleCategorySelector.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import WorkcenterParentChild from '@/@core/components/pamis/WorkcenterParentChild.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    Treeselect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewBreakdownSidebarActive',
    event: 'update:is-add-new-breakdown-sidebar-active',
  },
  props: {
    isAddNewBreakdownSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      required,
      alphaNum,
      email,
      currentCompany: null,
      companies: [],
      currentSite: null,
      sites: [],
      workCenters: [],
      currentWorkCenter: null,
      breakdownTypes: [],
      currentBreakdownType: null,
      currentIsActive: true,
      currentBreakdown: {
        Id: null,
        name: null,
        code: null,
        breakdonTypeID: null,
        workCenterID: null,
        isActive: false,
      },
      changeWorkCenterActive: false,
      workcenterChildEntities: [],
      value: null,
      options: null,
      isInitialEdit: false
    }
  },

  watch: {
    currentCompany(val) {
      if (!this.isInitialEdit) {
        this.currentBreakdown.workCenterID = null
      }
      if (val != null && val.id > 0) {
        this.$store.dispatch('pamis-breakdown/fetchSitesByCompanyId', val)
          .then(result => {
            console.log(result.data)
            if (result.data != null) {
              this.sites = []
              const siteList = result.data.filter(x => x.isDelete == false)
              this.sites = siteList
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Sites By Company Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.currentSite = null
        this.currentWorkCenter = null
        this.workcenterChildEntities = []
      }
    },
    currentSite(val) {
      if (!this.isInitialEdit) {
        this.currentBreakdown.workCenterID = null
      }
      if (val != null && val.id > 0) {
        this.$store.dispatch('pamis-breakdown/fetchWorkcentersBySiteId', val)
          .then(result => {
            console.log(result)
            if (result != null) {
              this.workCenters = []
              const workCenterList = result.data.filter(x =>x.isDelete == false && x.isActive == true)
              var list = this.formatParentChildForTreeSelect(workCenterList);
              list.forEach(node => this.removeEmptyChildren(node));

              this.workCenters = list;
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Workcenters By Site Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.workCenters = []
        this.currentWorkCenter = null
        this.workcenterChildEntities = []
      }
    },
    currentWorkCenter(val) {
      if (!this.isInitialEdit) {
        this.currentBreakdown.workCenterID = null
      }
      if (val != null && val.id > 0) {
        this.currentBreakdown.workCenterID = val.id
        this.$store.dispatch('pamis-breakdown/fetchWorkcentersByParentId', val)
          .then(result => {
            if (result != null) {
              this.workcenterChildEntities = []
              const workcenterChildList = result.data.filter(x => x.isDelete == false && x.parentID != 0)

              if (workcenterChildList != null && workcenterChildList.length > 0) {
                this.workcenterChildEntities.push({ childs: workcenterChildList, depth: 1 })
              }
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Workcenters By Site Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else { this.workcenterChildEntities = [] }
    },
    breakdowndata: {
      handler(n, o) {
        console.log(n)
        if (n == null || n == undefined || n.selectedBreakdown.id == null) {
          this.currentBreakdown = {
            Id: null,
            name: null,
            code: null,
            breakdonTypeID: null,
            workCenterID: null,
            isActive: false,
          }
          this.currentBreakdownType = null;
          this.currentCompany = null;
          this.currentSite = null;
          this.currentWorkCenter = null;
          
        } else {
          this.currentBreakdown = n.selectedBreakdown;
          this.isInitialEdit = false;

          if (n.selectedBreakdown.workCenterID != null) {
            this.isInitialEdit = true;
            this.fetchWorkcenterById(n.selectedBreakdown.workCenterID);
          }

          this.currentBreakdownType = n.selectedBreakdown.breakdownTypeID;
          this.currentIsActive = n.selectedBreakdown.isActive
        }
      },
      deep: true,
    },
  },
  created() {
    this.GetDataBreakdownTypes()
    this.fetchCompanies()
  },
  methods: {
    removeEmptyChildren(node) {
      if (node.children.length === 0) {
        delete node.children;
      } else {
        node.children.forEach(child => this.removeEmptyChildren(child));
      }
    },
    formatParentChildForTreeSelect(data) {
     const idToData = {};
      const rootItems = [];

      data.forEach(item => {
        const { id, name, parentID } = item;
        const newItem = { id, label: name, children: [] };

        idToData[id] = newItem;

        if (parentID === 0 || parentID === null) {
          rootItems.push(newItem);
        } else {
          const parentItem = idToData[parentID];
          if (parentItem) {
            if (!parentItem.children) {
              parentItem.children = [];
            }
            parentItem.children.push(newItem);
          }
        }
      });

      return rootItems;
    },
    changeCompany()
    {
      this.currentSite = null
      this.currentWorkCenter = null
    },
    changeSite()
    {
      this.currentWorkCenter = null
    },
    fetchWorkcenterById(val) {
      this.$store.dispatch('pamis-breakdown/fetchWorkcenterById',val)
        .then(result => {
          if (result != null) {
            const siteId = result.data.siteID;
            this.fetchSiteById(siteId);
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Data Colection BreakdownTypes By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchSiteById(val) {
      this.$store.dispatch('pamis-breakdown/fetchSiteById',val)
        .then(result => {
          if (result != null) {
            const site = {};
            site.id = result.data.id;
            site.name = result.data.name;
            this.currentSite = site;
            this.fetchCompanyById(result.data.companyID);
            this.currentWorkCenter = this.currentBreakdown.workCenterID;
            // this.GetDataWorkCenters(site);
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Data Colection BreakdownTypes By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchCompanyById(val) {
      this.$store.dispatch('pamis-breakdown/fetchCompanyById',val)
        .then(result => {
          if (result != null) {
            const company = {};
            company.id = result.data.id;
            company.name = result.data.name;
            this.currentCompany = company;
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Data Colection BreakdownTypes By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchCompanies() {
      this.companies = []
      this.currentCompany = null
      this.sites = []
      this.currentSite = null
      this.currentWorkCenter = null
      this.workcenterChildEntities = []
      this.$store.dispatch('pamis-breakdown/fetchCompanies')
        .then(result => {
          console.log(result)
          if (result != null) {
            const companyList = result.data.filter(x => x.isDelete == false)
            this.companies = companyList
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Companies By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setWorkcenterChild(val, depth) {
      this.currentBreakdown.workCenterID = null
      if (val != null && val.id > 0) {
        this.currentBreakdown.workCenterID = val.id
        this.$store.dispatch('pamis-breakdown/fetchWorkcentersByParentId', val)
          .then(result => {
            const responseData = result.data
            this.workcenterChildEntities = this.workcenterChildEntities.filter(item => !(item.depth > depth))
            if (responseData != null && responseData.length > 0) {
              const workcenterChildList = responseData.filter(x => x.isDelete == false && x.parentID != 0)
              const { workcenterChildEntities } = this

              if (workcenterChildList != null && workcenterChildList.length > 0) {
                this.workcenterChildEntities.push({ childs: workcenterChildList, depth: workcenterChildEntities.length + 1 })
              }
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Workcenters By Site Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        if (depth > 1) {
          this.currentBreakdown.workCenterID = this.workcenterChildEntities.find(item => item.depth == depth - 1).selectedChild.id
        } else {
          this.currentBreakdown.workCenterID = this.currentWorkCenter.id
        }
        this.workcenterChildEntities = this.workcenterChildEntities.filter(item => !(item.depth > depth))
      }
    },
    GetDataBreakdownTypes() {
      this.$store.dispatch('pamis-breakdown/fetchBreakdownTypes')
        .then(result => {
          if (result != null) {
            const breakdownTypeList = result.data.filter(x => x.isDelete == false && x.isActive == true)

            var list = this.formatParentChildForTreeSelect(breakdownTypeList);
            list.forEach(node => this.removeEmptyChildren(node));

            this.breakdownTypes = list
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Data Colection BreakdownTypes By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteBreakdown() {
      this.$store.dispatch('pamis-breakdown/deleteBreakdown', { id: this.currentBreakdown.id })
        .then(result => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-breakdown-sidebar-active', false)
          this.notification()
        })
    },

    submitForm() {
      if (this.currentBreakdown.name == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Breakdown Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else if (this.currentWorkCenter == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Workcenter Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } 
      else {
        let process = 'pamis-breakdown/addBreakdown'

        if (this.currentBreakdown != null && this.currentBreakdown.id != null) {
          process = 'pamis-breakdown/updateBreakdown'
        }

        this.currentBreakdown.workCenterID = this.currentWorkCenter
        this.currentBreakdown.breakdownTypeID = this.currentBreakdownType
        this.currentBreakdown.isActive = this.currentIsActive
        console.log(this.currentBreakdown)
        this.$store.dispatch(process, this.currentBreakdown)
          .then(result => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-breakdown-sidebar-active', false)
            this.notification()
          })
      }
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },

  inject: ['breakdowndata'],

  setup(props, { emit }) {
    const blankBreakdownData = {
      Id: null,
      name: null,
      code: null,
      breakdonTypeID: null,
      workCenterID: null,
      isActive: false,
    }

    const breakdownData = ref(JSON.parse(JSON.stringify(blankBreakdownData)))
    const resetBreakdownData = () => {
      breakdownData.value = JSON.parse(JSON.stringify(blankBreakdownData))
      emit('refetch-data')
      emit('update:is-add-new-breakdown-sidebar-active', false)
    }

    const onSubmit = () => {
      store.dispatch('pamis-breakdown/addBreakdown', currentBreakdown)
        .then(() => {
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBreakdownData)

    return {
      breakdownData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-breakdown-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
