import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBreakdowns(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/breakdown/Breakdown')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkCenters(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/WorkCenter')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanies(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Company')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSitesByCompanyId(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`pamis/admin/organization/Site/SitesByCompanyId/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkcentersBySiteId(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`pamis/admin/organization/WorkCenter/WorkcentersBySiteId/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkcentersByParentId(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`pamis/admin/organization/WorkCenter/WorkCentersByParentId?id=${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBreakdownTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/breakdown/breakdownType')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBreakdown(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/breakdown/breakdown/Create', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBreakdown(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`pamis/admin/breakdown/breakdown/Delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBreakdown(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`pamis/admin/breakdown/breakdown/Update?id=${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkcenterById(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`pamis/admin/organization/WorkCenter/${data}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSiteById(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`pamis/admin/organization/Site/${data}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanyById(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`pamis/admin/organization/Company/${data}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
